import { Fragment, useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import {
  setCustomerCode,
  updateCustomer,
  useAppDispatch,
  useAppSelector,
  createSmsCode,
} from '~/redux'
import { CodeInput } from '~/components'
import { styles } from './styles'

export const SaveDetailsScreen = () => {
  const dispatch = useAppDispatch()
  const { saveDetails, delivery, shipping, payment, meta } = useAppSelector(
    (s) => s.checkout,
  )
  const customer = useAppSelector((s) => s.checkout.customer)
  const phone = useAppSelector((s) => s.checkout.delivery.phone)

  const orderStatusUrl = useAppSelector((s) => s.checkout.meta.orderStatusUrl)

  const [sixDigitCode, setSixDigitCode] = useState('')

  const [seconds, setSeconds] = useState(60)
  const [resentedCode, setResentedCode] = useState(false)

  useEffect(() => {
    if (saveDetails.successfullySavedInformation) {
      setTimeout(() => {
        window.parent?.postMessage(
          JSON.stringify({ type: 'navigate', href: orderStatusUrl }),
          '*',
        )
      }, 5000)
    }
  }, [saveDetails.successfullySavedInformation])

  useEffect(() => {
    if (resentedCode) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
      }, 1000)
      if (seconds === 0) {
        clearInterval(interval)
        setResentedCode(false)
        setSeconds(60)
      }
      return () => clearInterval(interval)
    }
    return undefined
  })

  // sends typed 6 digit code with customer info
  const sendCode = () =>
    dispatch(
      updateCustomer({
        code: sixDigitCode,
        shop: meta.vendorId,
        phone: phone || '',
        fields: {
          delivery,
          selectedShippingRateId: shipping.selectedShippingRateId,
          ccName: payment.payment?.ccName,
          ccExpMonth: payment.payment?.ccExpMonth,
          ccExpYear: payment.payment?.ccExpYear,
          ccFirstSix: payment.payment?.ccFirstSix,
          ccLastFour: payment.payment?.ccLastFour,
          tokenexToken: customer.tokenexToken,
          tokenexTokenHMAC: customer.tokenexTokenHMAC,
        },
      }),
    ).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        dispatch(setCustomerCode(sixDigitCode))

        mixpanel.alias(action.payload.customer?.id)

        mixpanel.people.set({
          customerName: `${delivery?.firstName} ${delivery?.lastName}`,
          customerEmail: delivery?.email,
          customerPhone: delivery?.phone,
        })

        // if customer not exists track event "Registered"
        if (!customer.customerExists) {
          mixpanel.track('Registered')
        }

        // track event "Save information completed"
        mixpanel.track('Saving information completed')
      }

      return action.meta.requestStatus
    })

  const resendSmsCode = () => {
    dispatch(
      createSmsCode({
        phone: delivery.phone || '',
        shop: meta.vendorId,
      }),
    ).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        setResentedCode(true)
      }
    })
  }

  useEffect(() => {
    if (sixDigitCode !== '') {
      sendCode()
    }
  }, [sixDigitCode])

  useEffect(() => {
    if (!saveDetails.successfullySavedInformation) {
      dispatch(
        createSmsCode({
          phone: delivery.phone || '',
          shop: meta.vendorId,
        }),
      )

      // track event "Begin save information"
      mixpanel.track('Saving information begin')
    }
  }, [])

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <div css={styles.imageContainer}>
          {saveDetails?.updateCustomerLoading ? (
            <img css={styles.loader} src={require('./assets/loader.svg')} alt="loader" />
          ) : (
            <img src={require('./assets/phone-sms.svg')} alt="Phone sms" />
          )}
        </div>

        <div css={styles.title}>
          {saveDetails.successfullySavedInformation
            ? 'Your information was saved successfully'
            : 'Save your information'}{' '}
        </div>

        {saveDetails.successfullySavedInformation && (
          <div css={styles.infoTitle}>
            You will be redirected back to the store in 5 seconds...
          </div>
        )}

        {!saveDetails.successfullySavedInformation && (
          <Fragment>
            <div css={styles.infoTitle}>
              <span css={styles.enter}>Enter the code send to</span>{' '}
              <span css={styles.phone}>{phone}</span>
            </div>

            <CodeInput
              onCodeFill={(code) => setSixDigitCode(code)}
              isValid={saveDetails?.updateCustomerError === ''}
            />

            {saveDetails?.updateCustomerError && (
              <div css={styles.errorMessage}>{saveDetails?.updateCustomerError}</div>
            )}

            <div css={styles.bottom.container}>
              <span css={styles.bottom.text}>
                It may take a minute to receive your code. <br /> Haven&apos;t received
                it?{' '}
              </span>

              <span>
                <span
                  css={[
                    styles.bottom.button.base,
                    resentedCode && styles.bottom.button.disabled,
                  ]}
                  onClick={() => {
                    if (!resentedCode) {
                      resendSmsCode()
                    }
                  }}
                >
                  Resend a new code.{' '}
                </span>

                {resentedCode && <span>({seconds})</span>}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}
