import ReactDOM from 'react-dom'
import { Provider as ReduxStoreProvider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

import { config } from '~/shared/config'

import { store, history } from './redux'
import { App } from './app'

// initialize monitoring if not local
if (config.version !== 'local') {
  try {
    // start sentry
    Sentry.init({
      dsn: 'https://85dac68c08b44379871278aabd174ce8@o790881.ingest.sentry.io/5882985',
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsole({ levels: ['error'] }),
      ],

      tracesSampleRate: 1.0,
    })
    console.debug('successfully initialized sentry')
  } catch (error) {
    console.error('failed to initialize sentry', error)
  }
}

ReactDOM.render(
  <ReduxStoreProvider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </ReduxStoreProvider>,
  document.getElementById('root'),
)
