import { FC, useRef, useState, useEffect } from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { push } from 'connected-react-router'
import { UpdateDeliveryResponseBody } from '@softcery/qc-apiclient'
import mixpanel from 'mixpanel-browser'

import { Delivery } from '~/redux/types'
import { useAppDispatch, useAppSelector, updateDelivery, setDelivery } from '~/redux'
import { urlCreator } from '~/shared/lib/urlCreator'

import { Form } from './components'
import { validationSchema } from './validation'
import { COUNTRIES } from './constants'
import { getGoogleAnalyticsV4 } from '~/analytics'

export const DeliveryScreen: FC = () => {
  // get dispatch
  const dispatch = useAppDispatch()

  // delivery state
  const { delivery, checkout } = useAppSelector((s) => s.checkout)

  const formikRef = useRef<FormikProps<Delivery>>(null)

  // track event "Delivery section opened"
  useEffect(() => {
    mixpanel.track('Delivery section opened')
  }, [])

  // submit form
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const onSubmit = (data: Delivery): void => {
    dispatch(setDelivery(data))

    // update delivery
    dispatch(
      updateDelivery({
        checkout: {
          ...checkout.checkout,
          delivery: {
            ...data,
            countryCode: COUNTRIES.filter((country) => country.name === data.country)[0]
              ?.code,
          },
          // need to clear previous selected shipping rate id
          selectedShippingRateId: '',
        },
      }),
    )
      // after delivery updating get shipping rates
      .then((action) => {
        if (action.meta.requestStatus === 'fulfilled') {
          // track event "Delivery section completed"
          mixpanel.track('Delivery section completed')

          // navigate to shipping
          dispatch(push(urlCreator('/shipping')))
        }

        const { checkout: newCheckout } = action.payload as UpdateDeliveryResponseBody
        if (newCheckout) {
          getGoogleAnalyticsV4()?.addShippingInfo(newCheckout)

          mixpanel.register({
            customerName: `${newCheckout.delivery?.firstName} ${newCheckout.delivery?.lastName}`,
            customerEmail: newCheckout.delivery?.email,
            customerPhone: newCheckout.delivery?.phone,
          })
        }
      })
  }

  return (
    <Formik
      validateOnBlur={isSubmitted}
      validateOnChange={isSubmitted}
      initialValues={delivery}
      validationSchema={() => validationSchema(formikRef.current?.values.country || '')}
      onSubmit={(data) => onSubmit(data)}
      innerRef={formikRef}
    >
      {(props: FormikProps<FormikValues>) => (
        <Form {...props} setIsSubmitted={setIsSubmitted} />
      )}
    </Formik>
  )
}
