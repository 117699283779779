import { push } from 'connected-react-router'
import { useEffect, useMemo } from 'react'
import { SubmitButton, Error } from '~/components'
import {
  checkoutInitialState,
  updateCustomer,
  useAppDispatch,
  useAppSelector,
} from '~/redux'
import { urlCreator } from '~/shared/lib'
import { styles } from './styles'

export const FinishScreen = () => {
  const dispatch = useAppDispatch()
  const { orderStatusUrl, vendorId } = useAppSelector((s) => s.checkout.meta)
  const { saveDetails, customer, delivery, payment, shipping } = useAppSelector(
    (s) => s.checkout,
  )
  const savingDetailsEnableStatus =
    useAppSelector(
      (s) => s.checkout.checkout.checkoutSettings?.savingDetailsEnableStatus,
    ) || ''

  const onUpdateDetails = () => {
    if (!customer.code) {
      dispatch(push(urlCreator('/save-details')))
      return
    }

    dispatch(
      updateCustomer({
        phone: delivery.phone || '',
        shop: vendorId,
        code: customer.code,
        fields: {
          delivery,
          selectedShippingRateId: shipping.selectedShippingRateId,
          ccName: payment.payment?.ccName,
          ccExpMonth: payment.payment?.ccExpMonth,
          ccExpYear: payment.payment?.ccExpYear,
          ccFirstSix: payment.payment?.ccFirstSix,
          ccLastFour: payment.payment?.ccLastFour,
          tokenexToken: customer.tokenexToken,
          tokenexTokenHMAC: customer.tokenexTokenHMAC,
        },
      }),
    ).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        dispatch(push(urlCreator('/save-details')))
      }
    })
  }

  const closeCheckoutTimeout = useMemo(
    () =>
      setTimeout(() => {
        console.log('sending message to close checkout', { orderStatusUrl })

        // set items in ls to initial state
        localStorage.setItem(
          'quick_checkout_checkout.meta',
          JSON.stringify(checkoutInitialState.meta),
        )
        localStorage.setItem(
          'quick_checkout_checkout.checkout',
          JSON.stringify(checkoutInitialState.checkout),
        )

        window.parent?.postMessage(
          JSON.stringify({ type: 'navigate', href: orderStatusUrl }),
          '*',
        )
      }, 5000),
    [],
  )

  // redirect to order page after timeout
  useEffect(() => {
    // if clicked save details cancel close checkout timeout
    if (saveDetails?.createSmsLoading) {
      clearTimeout(closeCheckoutTimeout)
    }

    return () => clearTimeout(closeCheckoutTimeout)
  }, [saveDetails?.createSmsLoading])

  return (
    <div css={styles.container}>
      <img
        src={require('./assets/okhand.svg')}
        alt="Ok hand"
        data-testid="finish-ok_hand"
      />
      <div css={styles.title}>Your order is complete!</div>
      {!saveDetails?.createSmsLoading && (
        <div css={styles.infoTitle}>
          You will be redirected back to the store in 5 seconds...
        </div>
      )}
      {saveDetails?.createSmsError && <Error>{saveDetails?.createSmsError}</Error>}
      {savingDetailsEnableStatus === 'enabled' && (
        <SubmitButton
          text="Update my details for a faster checkout"
          loading={saveDetails?.createSmsLoading}
          extendStyle={{ button: styles.button }}
          showIcon={false}
          onClick={onUpdateDetails}
        />
      )}
    </div>
  )
}
