import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import querystring from 'querystring'
import {
  CreateCheckoutResponseBody,
  Checkout,
  CompleteCheckoutRequest,
  CompleteCheckoutResponseBody,
  UpdateDeliveryResponseBody,
  UpdateShippingRateResponseBody,
  UpdateDiscountResponseBody,
  DeleteDiscountResponseBody,
  UpdateCustomerRequest,
  UpdateCustomerResponse,
  CreateSmsCodeResponse,
  GetCustomerRequest,
  GetCustomerResponse,
  GetCheckCustomerRequest,
} from '@softcery/qc-apiclient'
import { apiService } from '~/shared/api'
import { Shipping, Payment, Delivery } from './types'

// apiErrorHandler used to handle api errors
const apiErrorHandler = async (
  res: any,
  errorMessage: string,
  rejectWithValue: (value: unknown) => unknown,
  setFieldError?: (message: string) => void,
): Promise<any> => {
  const data = await res.json()
  if (res.status === 500) {
    console.error(`${errorMessage}: ${data.message}`)
  } else {
    console.debug(`${errorMessage}: ${data.message}`)
  }
  setFieldError?.(data.message)
  return rejectWithValue(data)
}

// Common
export const createCheckout = createAsyncThunk(
  '/createCheckout',
  async ({
    vendorId,
    origin,
    items,
  }: {
    vendorId: string
    origin: string
    items: string
  }) => {
    try {
      return await apiService.createCheckout({
        vendorId,
        origin,
        fields: {
          lineItems: JSON.parse(items),
        },
      })
    } catch (res: any) {
      window.parent?.postMessage(JSON.stringify({ type: 'error' }), '*')
      return {}
    }
  },
)

export const updateDelivery = createAsyncThunk(
  '/updateDelivery',
  async (payload: { checkout: Checkout }, { getState, rejectWithValue }) => {
    const {
      checkout: { meta },
    } = getState() as { checkout: typeof checkoutInitialState }

    try {
      return await apiService.updateDelivery({
        vendorId: meta.vendorId,
        fields: {
          checkout: payload.checkout,
        },
      })
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to update delivery`, rejectWithValue)
    }
  },
)

// Shipping screen
export const updateShippingRate = createAsyncThunk(
  '/updateShippingRate',
  async (payload: { checkout: Checkout }, { getState, rejectWithValue }) => {
    const {
      checkout: { meta },
    } = getState() as { checkout: typeof checkoutInitialState }

    try {
      return await apiService.updateShippingRate({
        vendorId: meta.vendorId,
        fields: {
          checkout: payload.checkout,
        },
      })
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to update shipping rate`, rejectWithValue)
    }
  },
)

// Payment screen
export const updateDiscount = createAsyncThunk(
  '/updateDiscount',
  async (
    payload: { discountCode: string; setFieldError: (message: string) => void },
    { getState, rejectWithValue },
  ) => {
    const { checkout } = getState() as { checkout: typeof checkoutInitialState }
    try {
      payload.setFieldError('')
      return await apiService.updateDiscount({
        vendorId: checkout.meta.vendorId,
        fields: {
          checkout: {
            ...checkout.checkout.checkout,
            payment: {
              discountCode: payload.discountCode,
            },
          },
        },
      })
    } catch (res: any) {
      return apiErrorHandler(
        res,
        `Failed to update discount`,
        rejectWithValue,
        payload.setFieldError,
      )
    }
  },
)

export const deleteDiscount = createAsyncThunk(
  '/deleteDiscount',
  async (
    payload: {
      setFieldValue: (value: string) => void
      setFieldError: (message: string) => void
    },
    { getState, rejectWithValue },
  ) => {
    const { checkout } = getState() as { checkout: typeof checkoutInitialState }
    try {
      const response = await apiService.deleteDiscount({
        vendorId: checkout.meta.vendorId,
        fields: {
          checkout: {
            ...checkout.checkout.checkout,
          },
        },
      })
      payload.setFieldValue('')
      return response
    } catch (res: any) {
      return apiErrorHandler(
        res,
        `Failed to delete discount`,
        rejectWithValue,
        payload.setFieldError,
      )
    }
  },
)

export const completeCheckout = createAsyncThunk(
  '/completeCheckout',
  async (requestOptions: CompleteCheckoutRequest, { dispatch, rejectWithValue }) => {
    let response
    try {
      response = await apiService.completeCheckout(requestOptions)
      if (response.orderStatusUrl) {
        // save order status page url
        dispatch(setOrderStatusUrl(response.orderStatusUrl))
      }
      return response
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to complete checkout`, rejectWithValue)
    }
  },
)

export const createSmsCode = createAsyncThunk(
  '/createSmsCode',
  async (payload: { phone: string; shop: string }, { rejectWithValue }) => {
    try {
      return await apiService.createSmsCode({
        fields: {
          phone: payload.phone,
          shop: payload.shop,
        },
      })
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to send sms code`, rejectWithValue)
    }
  },
)

export const updateCustomer = createAsyncThunk(
  '/updateCustomer',
  async (requestOptions: UpdateCustomerRequest, { rejectWithValue }) => {
    try {
      return await apiService.updateCustomer(requestOptions)
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to save information`, rejectWithValue)
    }
  },
)

export const getCheckCustomer = createAsyncThunk(
  '/getCheckCustomer',
  async (requestOptions: GetCheckCustomerRequest, { rejectWithValue }) => {
    try {
      return await apiService.getCheckCustomer(requestOptions)
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to check customer`, rejectWithValue)
    }
  },
)

export const getCustomer = createAsyncThunk(
  '/getCustomer',
  async (requestOptions: GetCustomerRequest, { rejectWithValue }) => {
    try {
      return await apiService.getCustomer(requestOptions)
    } catch (res: any) {
      return apiErrorHandler(res, `Failed to get information`, rejectWithValue)
    }
  },
)

// initial slice state
export const checkoutInitialState = {
  meta: {
    vendorId: querystring.parse(window.location.search.slice(1)).vendorId as string,
    loading: false,
    error: '',
    orderStatusUrl: '',
  },

  checkout: {} as CreateCheckoutResponseBody,

  delivery: {
    email: '',
    phone: '1',
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    country: 'United States',
    city: '',
    province: '',
    provinceCode: '',
    zip: '',
  } as Delivery,

  shipping: {
    loading: false,
    shippingRates: [],
    selectedShippingRateId: '',
  } as Shipping,

  payment: {
    payment: {
      ccName: '',
      ccExpMonth: '',
      ccExpYear: '',
      ccFirstSix: '',
      ccLastFour: '',
      ccType: '',

      acceptsMarketingNewsletter: false,
      discountCode: '',
    },
    couponApplied: false,
    couponUpdating: false,
    loading: false,
  } as Payment,

  customer: {
    error: '',
    loading: '',
    customerExists: false,
    successfullyGetInformation: false,
    code: '',

    id: '',
    tokenexToken: '',
    tokenexTokenHMAC: '',

    codeUpdatedAt: 0,
  },

  saveDetails: {
    createSmsLoading: false,
    createSmsError: '',
    updateCustomerLoading: false,
    updateCustomerError: '',
    successfullySavedInformation: false,
    acceptsSavingDetails: false,
  },
}

// checkoutSlice provides state for all screens
export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: {
    // meta
    setOrderStatusUrl: (state, action: PayloadAction<string>) => {
      state.meta.orderStatusUrl = action.payload
    },

    setAcceptsSavingDetails: (state, action: PayloadAction<boolean>) => {
      state.saveDetails.acceptsSavingDetails = action.payload
    },

    // delivery
    setDelivery: (state, action: PayloadAction<Partial<Delivery>>) => {
      state.delivery = { ...state.delivery, ...action.payload }
    },

    // payment
    setPayment: (state, action: PayloadAction<Partial<Payment>>) => {
      state.payment = {
        ...state.payment,
        ...action.payload,
      }
    },

    setTokenex: (
      state,
      action: PayloadAction<{ tokenexToken: string; tokenexTokenHMAC: string }>,
    ) => {
      state.customer.tokenexToken = action.payload.tokenexToken
      state.customer.tokenexTokenHMAC = action.payload.tokenexTokenHMAC
    },

    setCustomerCode: (state, action: PayloadAction<string>) => {
      state.customer.code = action.payload
      state.customer.codeUpdatedAt = Date.now()
    },

    setCustomerExists: (state, action: PayloadAction<boolean>) => {
      state.customer.customerExists = action.payload
    },

    resetState: (state) => {
      state.customer = checkoutInitialState.customer
      state.delivery = checkoutInitialState.delivery
      state.shipping = checkoutInitialState.shipping
      state.payment = checkoutInitialState.payment
      state.saveDetails = checkoutInitialState.saveDetails
    },
  },
  extraReducers: (builder) => {
    // create checkout
    builder.addCase(createCheckout.pending, (state) => {
      state.meta.loading = true
    })
    builder.addCase(
      createCheckout.fulfilled,
      (state, action: PayloadAction<CreateCheckoutResponseBody>) => {
        state.checkout = action.payload
        state.payment.payment!.acceptsMarketingNewsletter =
          action.payload.checkoutSettings?.marketingCheckboxVisibility === 'show' &&
          action.payload.checkoutSettings?.marketingCheckboxDefaultState === 'checked'
        state.saveDetails.acceptsSavingDetails =
          action.payload.checkoutSettings?.savingDetailsEnableStatus === 'enabled' &&
          action.payload.checkoutSettings?.savingDetailsCheckboxDefaultState === 'checked'
        state.meta.loading = false
        state.meta.error = ''
      },
    )

    // update delivery
    builder.addCase(updateDelivery.pending, (state) => {
      state.meta.loading = true
    })
    builder.addCase(
      updateDelivery.fulfilled,
      (state, action: PayloadAction<UpdateDeliveryResponseBody>) => {
        // set updated checkout and shipping rates
        state.checkout.checkout = action.payload.checkout
        state.shipping.shippingRates = action.payload.checkout?.shippingRates || []

        // reset error and loading states
        state.meta.loading = false
        state.meta.error = ''
      },
    )
    builder.addCase(updateDelivery.rejected, (state, action) => {
      state.meta.loading = false
      state.meta.error =
        // error from api
        (action.payload as UpdateDeliveryResponseBody)?.message ||
        // error from redux
        action.error.message ||
        // default error
        'Failed to update delivery'
    })

    // update selected shipping rate
    builder.addCase(updateShippingRate.pending, (state) => {
      state.shipping.loading = true
      state.meta.loading = true
    })
    builder.addCase(
      updateShippingRate.fulfilled,
      (state, action: PayloadAction<UpdateShippingRateResponseBody>) => {
        // set updated checkout and selected shipping rate
        state.checkout.checkout = action.payload.checkout
        state.shipping.selectedShippingRateId =
          action.payload.checkout?.selectedShippingRateId || ''

        // reset loading state
        state.shipping.loading = false
        state.meta.loading = false
      },
    )
    builder.addCase(updateShippingRate.rejected, (state, action) => {
      state.shipping.loading = false
      state.meta.loading = false
      state.meta.error =
        (action.payload as UpdateShippingRateResponseBody)?.message ||
        action.error.message ||
        'Failed to update shipping rate'
    })

    // update discount
    builder.addCase(updateDiscount.pending, (state) => {
      state.meta.loading = true
      state.payment.couponUpdating = true
    })
    builder.addCase(
      updateDiscount.fulfilled,
      (state, action: PayloadAction<UpdateDiscountResponseBody>) => {
        state.checkout.checkout = { ...action.payload.checkout }
        state.payment.couponUpdating = false
        state.meta.loading = false
        state.payment.couponApplied = true
        state.payment.payment!.discountCode =
          action.payload.checkout?.payment?.discountCode
      },
    )
    builder.addCase(updateDiscount.rejected, (state) => {
      state.payment.couponUpdating = false
      state.meta.loading = false
    })

    // delete discount
    builder.addCase(deleteDiscount.pending, (state) => {
      state.meta.loading = true
      state.payment.couponUpdating = true
    })
    builder.addCase(
      deleteDiscount.fulfilled,
      (state, action: PayloadAction<DeleteDiscountResponseBody>) => {
        state.checkout.checkout = { ...action.payload.checkout }
        state.payment.couponUpdating = false
        state.meta.loading = false
        state.payment.couponApplied = false
        state.payment.payment!.discountCode =
          action.payload.checkout?.payment?.discountCode
      },
    )
    builder.addCase(deleteDiscount.rejected, (state) => {
      state.payment.couponUpdating = false
      state.meta.loading = false
    })

    // complete checkout
    builder.addCase(completeCheckout.pending, (state) => {
      state.payment.loading = true
    })
    builder.addCase(completeCheckout.fulfilled, (state) => {
      state.payment.loading = false
      state.meta.error = ''
    })
    builder.addCase(completeCheckout.rejected, (state, action) => {
      state.payment.loading = false
      state.meta.error =
        (action.payload as CompleteCheckoutResponseBody).message ||
        action.error?.message ||
        `Failed to charge card`
    })

    // create sms code
    builder.addCase(createSmsCode.pending, (state) => {
      state.saveDetails.createSmsLoading = true
    })
    builder.addCase(createSmsCode.fulfilled, (state) => {
      state.saveDetails.createSmsLoading = false
      state.saveDetails.createSmsError = ''
    })
    builder.addCase(createSmsCode.rejected, (state, action) => {
      state.saveDetails.createSmsLoading = false
      state.saveDetails.createSmsError =
        (action.payload as CreateSmsCodeResponse).message ||
        action.error?.message ||
        `Failed to send sms`
    })

    // save customer information
    builder.addCase(updateCustomer.pending, (state) => {
      state.saveDetails.updateCustomerLoading = true
    })
    builder.addCase(updateCustomer.fulfilled, (state) => {
      state.saveDetails.updateCustomerLoading = false
      state.saveDetails.updateCustomerError = ''
      state.saveDetails.successfullySavedInformation = true
    })
    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.saveDetails.updateCustomerLoading = false
      state.saveDetails.updateCustomerError =
        (action.payload as UpdateCustomerResponse).message ||
        action.error?.message ||
        `Failed to save information`
    })

    builder.addCase(getCheckCustomer.fulfilled, (state) => {
      state.customer.customerExists = true
    })

    // save customer information
    builder.addCase(
      getCustomer.fulfilled,
      (state, action: PayloadAction<GetCustomerResponse>) => {
        state.delivery = { ...action.payload.customer?.delivery, provinceCode: '' }
        state.shipping.selectedShippingRateId =
          action.payload.customer?.selectedShippingRateId || ''
        state.payment.payment!.ccName = action.payload.customer?.ccName
        state.payment.payment!.ccExpMonth = action.payload.customer?.ccExpMonth
        state.payment.payment!.ccExpYear = action.payload.customer?.ccExpYear
        state.payment.payment!.ccFirstSix = action.payload.customer?.ccFirstSix
        state.payment.payment!.ccLastFour = action.payload.customer?.ccLastFour
        state.payment.payment!.ccType = action.payload.customer?.ccType
        state.customer.tokenexToken = action.payload.customer?.tokenexToken || ''
        state.customer.tokenexTokenHMAC = action.payload.customer?.tokenexTokenHMAC || ''
        state.customer.successfullyGetInformation = true
      },
    )
    builder.addCase(getCustomer.rejected, (state, action) => {
      state.customer.error = (action.payload as GetCustomerResponse).message || ''
    })
  },
})

export const {
  setDelivery,
  setOrderStatusUrl,
  setAcceptsSavingDetails,
  setPayment,
  setCustomerCode,
  setTokenex,
  setCustomerExists,
  resetState,
} = checkoutSlice.actions
