import { FC, HTMLAttributes } from 'react'
import { SerializedStyles } from '@emotion/react'
import { styles } from './styles'

interface CheckboxWithLabelProps extends HTMLAttributes<HTMLDivElement> {
  dataTestId?: string

  label: JSX.Element | string
  extendStyle?: SerializedStyles

  checked: boolean
  setChecked: () => void
}

export const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({
  dataTestId,

  label,
  extendStyle,

  checked,
  setChecked,

  ...props
}) => (
  <div {...props} css={[styles.container, extendStyle]} data-testid={dataTestId}>
    <div
      onClick={setChecked}
      css={[styles.checkbox.base, checked && styles.checkbox.checked]}
    >
      {checked && (
        <img src={require('./assets/checked.svg')} alt="checked" css={styles.icon} />
      )}
    </div>

    <label css={styles.label}>{label}</label>
  </div>
)
