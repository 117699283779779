import { FC, Fragment, useEffect, useState } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import qs from 'querystring'
import { useWindowSize } from 'react-use'
import { push } from 'connected-react-router'
import { ThemeProvider, Theme, Global } from '@emotion/react'
import InnerHTML from 'dangerously-set-html-content'
import { CreateCheckoutResponseBody } from '@softcery/qc-apiclient'
import mixpanel from 'mixpanel-browser'

import { Header, Popup, Footer, Sha, Loader } from '~/components'
import { mixpanelProjectToken } from '~/shared/config'
import { createCheckout, resetState, useAppDispatch, useAppSelector } from '~/redux'
import { BreakpointWidth } from '~/shared/styles'
import { SaveDetailsScreen } from '~/screens/save-details'

import { DeliveryScreen, ShippingScreen, PaymentScreen, FinishScreen } from '../screens'
import { getUrlParams, urlCreator } from '../shared/lib'
import { global, makeTheme, styles } from './styles'

import {
  getFacebookAnalytics,
  getGoogleAnalyticsV3,
  getGoogleAnalyticsV4,
} from '../analytics'

export const App: FC = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { width } = useWindowSize()

  // state for checkout theme
  const [theme, setTheme] = useState<Theme>()
  // state for checkout showing
  const [showCheckout, setShowCheckout] = useState<boolean>(false)
  // state for cart component showing
  const [showCart, setShowCart] = useState<boolean>(width > BreakpointWidth.TABLET)
  // get vendorId, origin and items from query string
  const { vendorId, origin, items, platform } = qs.parse(window.location.search.slice(1))

  const scripts =
    useAppSelector((s) => s.checkout.checkout.checkoutSettings?.scripts) || ''
  const checkout = useAppSelector((s) => s.checkout.checkout)
  const customer = useAppSelector((s) => s.checkout.customer)

  useEffect(() => {
    // creating checkout in Quick Checkout Engine
    dispatch(
      createCheckout({
        vendorId: vendorId as string,
        origin: origin as string,
        items: items as string,
      }),
    ).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        // set checkout theme to values from api
        setTheme(
          makeTheme((action.payload as CreateCheckoutResponseBody).checkoutStyles || {}),
        )
        // show checkout
        setShowCheckout(true)
        dispatch(push(urlCreator('/delivery')))
      }
    })
  }, [])

  useEffect(() => {
    if (!showCheckout) {
      return
    }

    // get 30 days ago date
    const date = new Date()
    date.setDate(date.getDate() - 30)

    // reset all user data if 30 days or more have pressed since the code verification
    if (customer.codeUpdatedAt && customer.codeUpdatedAt <= date.getTime()) {
      dispatch(resetState())
    }

    if (checkout.checkout) {
      getGoogleAnalyticsV3()?.beginCheckout(checkout.checkout)
      getGoogleAnalyticsV4()?.beginCheckout(checkout.checkout)
      getFacebookAnalytics()?.beginCheckout(checkout.checkout)
    }
  }, [showCheckout])

  useEffect(() => {
    // initialize mixpanel analytics
    mixpanel.init(mixpanelProjectToken, { debug: false })

    mixpanel.register({
      storeDomain: vendorId,
      storePlatform: platform,
      numberOfProducts: checkout.checkout?.products?.length,
      totalPrice: checkout.checkout?.totalPrice,
      productIDs: checkout?.checkout?.products?.map((product) => product.productId),
    })

    if (showCheckout) {
      // track event "Checkout opened"
      mixpanel.track('Checkout opened')
    }
  }, [showCheckout])

  return (
    <Fragment>
      <Global styles={global} />
      {checkout.checkoutSettings?.scripts !== '' && <InnerHTML html={scripts} />}
      {checkout.checkoutSettings?.googleAnalyticsV3Id !== '' && (
        <InnerHTML
          html={`
            <!-- Google Analytics -->
            <script>
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            ga('create', '${checkout.checkoutSettings?.googleAnalyticsV3Id}', 'auto', {
              'clientId': '${getUrlParams('clientId')}',
              'cookieFlags': "SameSite=None; Secure",
              'allowLinker': true,
            });
            ga('require', 'ec');
            ga('send', 'pageview');
            </script>
            <!-- End Google Analytics -->
          `}
        />
      )}
      {checkout.checkoutSettings?.googleAnalyticsMeasurementId !== '' && (
        <InnerHTML
          html={`
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=${checkout.checkoutSettings?.googleAnalyticsMeasurementId}"
        ></script>
        <script>
          window.dataLayer = window.dataLayer || []
          function gtag() {
            dataLayer.push(arguments)
          }
          gtag('js', new Date())
          gtag('config','${checkout.checkoutSettings?.googleAnalyticsMeasurementId}', {
            cookie_flags: 'max-age=7200;secure;samesite=none',
            linker: {
              accept_incoming: true,
            }
          })
        </script>
      `}
        />
      )}
      {checkout.checkoutSettings?.facebookPixelId !== '' && (
        <InnerHTML
          html={`
        <!-- Facebook Pixel Code -->
        <script>
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${checkout.checkoutSettings?.facebookPixelId}');
          fbq('track', 'PageView');
        </script>
        <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${checkout.checkoutSettings?.facebookPixelId}&ev=PageView&noscript=1"
        /></noscript>
        <!-- End Facebook Pixel Code -->
      `}
        />
      )}
      {process.env.NODE_ENV === 'production' && (
        <InnerHTML
          html={`
          <!-- Hotjar Tracking Code for my site -->
          <script>
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2801539,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          </script>
      `}
        />
      )}

      {!showCheckout ? ( // display loader during checkout creating
        <div data-testid="loader" css={styles.container(platform as string)}>
          <Loader extendStyle={styles.loader} fill="rgba(0, 0, 0, 0.35)" />
        </div>
      ) : (
        <ThemeProvider theme={theme || {}}>
          <Popup
            showCart={showCart}
            // redirect to order url for closing popup
            onClose={() =>
              window.parent?.postMessage(JSON.stringify({ type: 'close' }), '*')
            }
          >
            <Header showCart={showCart} setShowCart={setShowCart} />

            <Switch>
              <Route component={DeliveryScreen} path="/delivery" exact />

              <Route component={ShippingScreen} path="/shipping" exact />

              <Route component={PaymentScreen} path="/payment" exact />

              <Route component={FinishScreen} path="/finish" exact />

              <Route component={SaveDetailsScreen} path="/save-details" exact />

              <Redirect from="*" to={urlCreator('/delivery')} />
            </Switch>
            {pathname !== '/save-details' && <Footer />}
          </Popup>
          <Sha />
        </ThemeProvider>
      )}
    </Fragment>
  )
}
