import { useTheme } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface ArrowLeftIconProps {
  rotateRight: boolean
}

export const ArrowLeftIcon: FC<ArrowLeftIconProps> = ({ rotateRight }) => {
  const theme = useTheme()

  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={rotateRight && styles.rotateRight}
    >
      <path
        d="M5.80001 1.50001L2.30001 5.00001L5.80001 8.50001L5.10001 9.90001L0.200012 5.00001L5.10001 0.100006L5.80001 1.50001Z"
        fill={theme.SUMMARY_BUTTON_TEXT_COLOR}
      />
    </svg>
  )
}
