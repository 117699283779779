import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { styles } from './styles'
import { ProgressBar } from '..'

// Common component that used on every screen
export const Footer: FC = () => {
  const { pathname } = useLocation()
  // progress value changes due to screen
  const renderSwitch = () => {
    switch (pathname) {
      case '/delivery':
        return 30
      case '/shipping':
        return 80
      case '/payment':
        return 98
      default:
        return 100
    }
  }

  return (
    <div css={styles.container}>
      <ProgressBar progress={renderSwitch()} />
      {/* <div css={styles.secured}> */}
      {/* <img src={require('./assets/lock.svg')} alt="lock" /> */}
      {/* <span>Secured by Eye4Fraud</span> */}
      {/* </div> */}
    </div>
  )
}
