import { FC } from 'react'
import { styles } from './styles'

interface PriceProps {
  title: string
  price?: string
  accent?: boolean
  discountedPrice?: string
  customTextPrice?: string
  currencySymbol?: string
  dataTestId?: string
}

export const Price: FC<PriceProps> = ({
  title,
  price,
  customTextPrice,
  accent,
  discountedPrice,
  currencySymbol,
  dataTestId,
}) => {
  return (
    <div css={styles.container}>
      <span>{title}</span>
      <div
        css={[styles.price.container, accent && styles.price.accent]}
        data-testid={dataTestId}
      >
        <span css={!customTextPrice && discountedPrice && styles.price.lineThrough}>
          {customTextPrice || currencySymbol + parseFloat(price || '0').toFixed(2)}
        </span>
        {!customTextPrice && discountedPrice && (
          <span css={styles.price.discountedPrice}>
            &nbsp;{currencySymbol + parseFloat(discountedPrice).toFixed(2)}
          </span>
        )}
      </div>
    </div>
  )
}
