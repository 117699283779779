import { Checkout } from '@softcery/qc-apiclient'
import { history } from '~/redux'
import { getCheckoutItemsFromProducts } from './utils'

/**
 * Provides methods for interaction with Google Analytics v4
 */
export class GoogleAnalytics4 {
  constructor(private gtag: Gtag.Gtag) {}

  public beginCheckout(checkout: Checkout): void {
    this.gtag('event', 'begin_checkout', {
      currency: checkout.currency,
      value: checkout.totalPrice,
      shipping: checkout.shippingRates?.filter(
        (rate) => rate.vendorId === checkout.selectedShippingRateId,
      ),
      items: getCheckoutItemsFromProducts(checkout.products),
    })
    // need this for google analytics page views tracking
    history.listen(() =>
      this.gtag('event', 'page_view', {
        page_location: window.location.href,
      }),
    )
  }

  public addShippingInfo(checkout: Checkout): void {
    this.gtag('event', 'add_shipping_info', {
      currency: checkout.currency,
      value: checkout.totalPrice,
      items: getCheckoutItemsFromProducts(checkout.products),
    })
  }

  public addPaymentInfo(checkout: Checkout): void {
    this.gtag('event', 'add_payment_info', {
      currency: checkout.currency,
      value: checkout.totalPrice,
      payment_type: 'Credit Card',
      items: getCheckoutItemsFromProducts(checkout.products),
    })
  }

  public purchase(checkout: Checkout): void {
    this.gtag('event', 'purchase', {
      currency: checkout.currency,
      value: checkout.totalPrice,
      tax: checkout.totalTaxes,
      shipping: checkout.shippingRates?.filter(
        (rate) => rate.vendorId === checkout.selectedShippingRateId,
      )[0].price,
      payment_type: 'Credit Card',
      items: getCheckoutItemsFromProducts(checkout.products),
    })
  }
}
