import { FC, ForwardedRef, InputHTMLAttributes, forwardRef, ChangeEvent } from 'react'
import { Field, FieldProps, FormikValues } from 'formik'
import { styles } from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: ForwardedRef<HTMLInputElement>
  label: string
  icon?: JSX.Element
  success?: boolean

  onChangeCustom?: (e: ChangeEvent<HTMLInputElement>) => ChangeEvent<HTMLInputElement>
}

// Input provides a component that can be used with formik
// Used on Delivery, Payment screens
export const Input: FC<InputProps> = forwardRef(
  (
    {
      label,
      name,
      icon,
      success,
      autoComplete,
      readOnly,

      onChangeCustom,
      onFocus,
      onBlur,

      ...props
    },
    ref,
  ) => (
    <Field {...props} name={name}>
      {({
        field,
        meta,
        form: { handleChange, handleBlur },
      }: FieldProps<FormikValues>) => (
        <div
          css={[
            styles.container.base,
            icon && styles.container.withIcon,
            meta.error && styles.error.container,
            success && styles.success.container,
          ]}
        >
          {icon}
          <input
            {...field}
            onFocus={onFocus}
            onBlur={(e) => {
              handleBlur(e)
              if (onBlur) {
                onBlur(e)
              }
            }}
            value={field.value as unknown as string}
            ref={ref}
            type="text"
            autoComplete={autoComplete}
            placeholder={label}
            readOnly={readOnly}
            onChange={(e) => {
              let customEvent
              if (onChangeCustom) {
                customEvent = onChangeCustom(e)
              }
              handleChange(customEvent || e)
            }}
            data-testid={`${name}_input`}
            data-hj-allow
          />
          <label>{label}</label>
          <div
            css={styles.error.message}
            id={`${name}_input-error`}
            data-testid="input-error"
          >
            {meta.error}
          </div>
        </div>
      )}
    </Field>
  ),
)
