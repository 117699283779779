import * as Yup from 'yup'
import valid from 'card-validator'

// validation for Payment screen fields
export const validationSchema = Yup.object().shape({
  ccName: Yup.string()
    .required('Required')
    .test('test-name', 'Invalid name', (value) => valid.cardholderName(value).isValid),
  ccExpMonth: Yup.string()
    .required('Required')
    .test('expiration-month', 'Invalid', (value) => {
      return valid.expirationMonth(value).isValid && value?.length === 2
    }),
  ccExpYear: Yup.string()
    .required('Required')
    .test('expiration-year', 'Invalid', (value) => {
      // max year current year + 50
      return valid.expirationYear(value, 50).isValid
    }),
})
