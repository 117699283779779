import { useTheme } from '@emotion/react'
import { FC } from 'react'

export const PhoneIcon: FC = () => {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.97"
      height="14.969"
      viewBox="0 0 14.97 14.969"
    >
      <path
        fill={theme.SUMMARY_TEXT_COLOR}
        d="M1054.42,906.2a0.567,0.567,0,0,1,.41.234,0.791,0.791,0,0,1,.14.44,13.378,13.378,0,0,1-1.84,6.858,13.681,13.681,0,0,1-4.9,4.894,13.351,13.351,0,0,1-6.85,1.847,0.719,0.719,0,0,1-.44-0.147,0.553,0.553,0,0,1-.24-0.41l-0.7-3.048a0.647,0.647,0,0,1,.06-0.469,0.593,0.593,0,0,1,.35-0.323l3.28-1.406a0.647,0.647,0,0,1,.43-0.03,0.909,0.909,0,0,1,.39.235l1.44,1.758a10.323,10.323,0,0,0,3.06-2.139,10.588,10.588,0,0,0,2.12-3.048l-1.75-1.436a0.877,0.877,0,0,1-.24-0.4,0.621,0.621,0,0,1,.03-0.425l1.41-3.283a0.618,0.618,0,0,1,.32-0.351,0.666,0.666,0,0,1,.47-0.059Z"
        transform="translate(-1040 -905.5)"
      />
    </svg>
  )
}
