import { FC } from 'react'
import { AddIcon } from './components'
import { styles } from './styles'

interface AddTitleProps {
  text: string
  onClick: () => void
  dataTestId?: string
}

export const AddTitle: FC<AddTitleProps> = ({ onClick, text, dataTestId }) => {
  return (
    <div css={styles.container} onClick={onClick} data-testid={dataTestId}>
      <AddIcon />
      <span>{text}</span>
    </div>
  )
}
