import { CallHistoryMethodAction } from 'connected-react-router'
import { FC } from 'react'
import { styles } from './styles'

interface InfoProps {
  title: string
  values: string[]
  dataTestId?: string
  bodyTestId?: string
  editId?: string

  onEdit: () => CallHistoryMethodAction
}

export const Info: FC<InfoProps> = ({
  title,
  values,
  dataTestId,
  bodyTestId,
  editId,

  onEdit,
}) => {
  return (
    <div css={styles.container} data-testid={dataTestId}>
      <div css={styles.header}>
        <div>{title}</div>
        <a id={editId} onClick={onEdit}>
          Edit
        </a>
      </div>
      <div css={styles.body} data-testid={bodyTestId}>
        {values.map((value, index) => (
          <div key={index}>{value}</div>
        ))}
      </div>
    </div>
  )
}
