import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { save, load } from 'redux-localstorage-simple'
import { createBrowserHistory } from 'history'

import { checkoutInitialState, checkoutSlice } from './checkoutSlice'

// browser history
export const history = createBrowserHistory()
// get persisted state
const preloadedState = load({
  namespace: 'quick_checkout',
  states: [
    'checkout.meta',
    'checkout.checkout',
    'checkout.delivery',
    'checkout.shipping',
    'checkout.payment',
    'checkout.saveDetails',
    'checkout.customer',
  ],
})

// FIXME: clear meta checkout
// @ts-ignore
if (preloadedState?.checkout?.meta) {
  // @ts-ignore
  preloadedState!.checkout!.meta = checkoutInitialState.meta
}

// need this for not saving couponApplied state
// @ts-ignore
if (preloadedState?.checkout?.payment.couponApplied) {
  // @ts-ignore
  preloadedState!.checkout!.payment.couponApplied =
    checkoutInitialState.payment.couponApplied
}

// store provides redux store for whole app
export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    checkout: checkoutSlice.reducer,
  },

  middleware: [
    ...getDefaultMiddleware().concat(
      routerMiddleware(history),
      // middleware for saving state in localstorage
      // TODO remove saving vendorId, loading, error
      save({
        namespace: 'quick_checkout',
        states: [
          'checkout.meta',
          'checkout.checkout',
          'checkout.delivery',
          'checkout.shipping',
          'checkout.payment',
          'checkout.saveDetails',
          'checkout.customer',
        ],
      }),
    ),
  ],
  // preload saved state from local storage
  preloadedState,
})

// Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
