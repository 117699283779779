import { FC, Fragment } from 'react'
import { useTheme } from '@emotion/react'
import getSymbolFromCurrency from 'currency-symbol-map'

import { useAppSelector } from '~/redux'
import { Loader } from '~/components'

import { styles } from './styles'
import { Product, Price } from './components'
import { PhoneIcon } from './assets'

interface CartProps {
  visible: boolean
}

// Cart displays cart prices and products.
export const Cart: FC<CartProps> = ({ visible }) => {
  const theme = useTheme()
  // get state
  const { loading } = useAppSelector((s) => s.checkout.meta)
  const { checkout, checkoutSettings } = useAppSelector((s) => s.checkout?.checkout)

  // get selected shipping rate
  const selectedShippingRate = checkout?.shippingRates?.filter(
    (rate) => rate.vendorId === checkout.selectedShippingRateId,
  )[0]

  // get currency symbol
  const currencySymbol = getSymbolFromCurrency(checkout?.currency || '')
  let deliveryCustomTitlePrice
  // local pickup
  if (
    selectedShippingRate?.title?.toLowerCase() ===
    checkoutSettings?.vendorLocalPickupRateTitle?.toLowerCase()
  ) {
    deliveryCustomTitlePrice =
      checkoutSettings?.vendorLocalPickupRateTitle?.toUpperCase() || ''
  }
  // free shipping
  else if (
    parseFloat(selectedShippingRate?.price || '').toFixed(2) === '0.00' ||
    parseFloat(checkout?.discountedDeliveryPrice || '').toFixed(2) === '0.00'
  ) {
    deliveryCustomTitlePrice = 'FREE'
  }
  return (
    <div id="cart-information-wrapper" css={styles.wrapper}>
      <div
        css={[
          styles.container.base,
          visible && styles.container.visible,
          loading && styles.container.loading,
        ]}
        data-testid="cart-information"
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <Loader dataTestId="cart-loader" fill={theme.SUMMARY_BACKGROUND_COLOR} />
        ) : (
          <Fragment>
            <div css={styles.header}>
              <div css={styles.h3}>Summary</div>
              <span data-testid="cart-items_length">
                {/* lineItems count in cart */}
                {`${checkout?.lineItems?.length} ${
                  (checkout?.lineItems?.length || 0) > 1 ? 'items' : 'item'
                }`}
              </span>
            </div>

            <div css={styles.priceInfo}>
              <Price
                title="Subtotal"
                price={checkout?.subtotalPrice}
                currencySymbol={currencySymbol}
                dataTestId="cart-subtotal_price"
              />
              <Price
                title="Delivery"
                price={selectedShippingRate?.price}
                customTextPrice={deliveryCustomTitlePrice}
                discountedPrice={checkout?.discountedDeliveryPrice}
                currencySymbol={currencySymbol}
                dataTestId="cart-delivery_price"
              />
              <Price
                title="Tax"
                price={checkout?.totalTaxes}
                currencySymbol={currencySymbol}
                dataTestId="cart-tax_price"
              />
              {Number(checkout?.totalDiscount) > 0 && (
                <Price
                  title="Discount"
                  price={checkout?.totalDiscount}
                  currencySymbol={`- ${currencySymbol}`}
                  dataTestId="cart-total_discount"
                />
              )}
              <Price
                accent
                title="Total"
                price={checkout?.totalPrice}
                currencySymbol={currencySymbol}
                dataTestId="cart-total_price"
              />
            </div>

            <span>ITEMS</span>
            <div css={styles.products} data-testid="cart-items">
              {checkout?.products?.map((product) => (
                <Product product={product} key={product.productId} />
              ))}
            </div>

            {checkoutSettings?.needHelpEnableStatus === 'enabled' && (
              <div css={styles.container.help.container}>
                <div css={styles.h3}>{checkoutSettings?.needHelpHeading}</div>
                <div css={styles.container.help.call}>
                  <PhoneIcon />
                  {checkoutSettings?.needHelpTitle}
                </div>
                <span>{checkoutSettings?.needHelpDescription}</span>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  )
}
