import { useTheme } from '@emotion/react'
import { FC, useState } from 'react'
import { usePlacesAutocompleteServiceResponse } from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { Loader, Input } from '~/components'
import { PlaceOption } from '~/redux/types'
import { styles } from './styles'

interface GoogleAutocompleteInputProps {
  label: string
  error?: string
  name: string
  dropdownDataTestId?: string
  places: usePlacesAutocompleteServiceResponse

  onOptionClick: (option: PlaceOption) => void
}

export const GoogleAutocompleteInput: FC<GoogleAutocompleteInputProps> = ({
  onOptionClick,
  places,
  dropdownDataTestId,

  ...props
}) => {
  const theme = useTheme()
  const [showOptions, setShowOptions] = useState<boolean>(false)

  return (
    <div css={styles.container} onBlur={() => setShowOptions(false)}>
      <Input
        {...props}
        onFocus={() => setShowOptions(true)}
        onBlur={() => setShowOptions(false)}
        onChangeCustom={(e) => {
          places.getPlacePredictions({ input: e.target.value })
          if (!showOptions) {
            setShowOptions(true)
          }
          return e
        }}
      />
      {(places.placePredictions.length > 0 && showOptions) ||
      places.isPlacePredictionsLoading ? (
        <div css={styles.dropdown.container} data-testid={dropdownDataTestId}>
          {places.isPlacePredictionsLoading && (
            <Loader extendStyle={styles.loader} fill={theme.CHECKOUT_BACKGROUND_COLOR} />
          )}

          {!places.isPlacePredictionsLoading &&
            places.placePredictions.map((option: PlaceOption) => {
              return (
                <div
                  css={styles.dropdown.item}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    onOptionClick(option)
                    setShowOptions(false)
                  }}
                  key={option.place_id}
                >
                  {option.description}
                </div>
              )
            })}
        </div>
      ) : null}
    </div>
  )
}
