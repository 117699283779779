import { Checkout } from '@softcery/qc-apiclient'
import { history } from '~/redux'
import { getCheckoutItemsFromProducts } from './utils'

/**
 * Provides methods for interaction with Google Analytics v3
 */
export class GoogleAnalytics3 {
  constructor(private ga: UniversalAnalytics.ga) {}

  public beginCheckout(checkout: Checkout): void {
    ga('set', 'currencyCode', checkout.currency)

    checkout?.products?.forEach((product) => {
      ga('ec:addProduct', {
        id: product.productId,
        name: product.title,
        price: product.price,
        quantity: product.quantity,
      })
    })

    const shippingRates = checkout.shippingRates?.filter(
      (rate) => rate.vendorId === checkout.selectedShippingRateId,
    )
    this.ga('ec:setAction', 'begin_checkout', {
      id: checkout.id,
      revenue: checkout.totalPrice,
      shipping: shippingRates && shippingRates.length ? shippingRates[0].price : '',
      items: getCheckoutItemsFromProducts(checkout.products),
    })

    this.ga('send', 'event', 'begin_checkout')

    // need this for google analytics page views tracking
    history.listen(() => {
      ga('send', {
        hitType: 'pageview',
        page: `${window.location.pathname}`,
      })
    })
  }

  public addPaymentInfo(checkout: Checkout): void {
    this.ga('set', 'currencyCode', checkout.currency)

    this.ga('ec:setAction', 'checkout_option', {
      revenue: checkout.totalPrice,
      items: getCheckoutItemsFromProducts(checkout.products),
      option: 'Credit Card',
    })
  }

  public purchase(checkout: Checkout): void {
    this.ga('set', 'currencyCode', checkout.currency)

    this.ga('ec:setAction', 'purchase', {
      id: checkout.id,
      revenue: checkout.totalPrice,
      tax: checkout.totalTaxes,
      shipping: checkout.shippingRates?.filter(
        (rate) => rate.vendorId === checkout.selectedShippingRateId,
      )[0].price,
      items: getCheckoutItemsFromProducts(checkout.products),
      option: 'Credit Card',
    })

    ga('send', 'pageview')
  }
}
