import { FC, Fragment, Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'
import getSymbolFromCurrency from 'currency-symbol-map'

import { useAppDispatch, useAppSelector } from '~/redux'
import { urlCreator } from '~/shared/lib/urlCreator'

import { Button } from '..'
import { Step, Info, ShoppingCartIcon, ArrowLeftIcon } from './components'
import { styles } from './styles'

interface HeaderProps {
  showCart: boolean
  setShowCart: Dispatch<SetStateAction<boolean>>
}

// Common component that used on every screen
export const Header: FC<HeaderProps> = ({ showCart, setShowCart }) => {
  // get dispatch and pathname
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  // get state
  const { loading } = useAppSelector((s) => s.checkout.meta)
  const { checkout, checkoutSettings } = useAppSelector((s) => s.checkout.checkout)
  const { logoUrl } = useAppSelector((s) => s.checkout.checkout.checkoutStyles || {})
  const {
    firstName,
    lastName,
    email,
    country,
    province,
    provinceCode,
    zip,
    city,
    address1,
  } = useAppSelector((s) => s.checkout.delivery)

  // get selected shipping rate
  const selectedShippingRate = checkout?.shippingRates?.filter(
    (rate) => rate.vendorId === checkout.selectedShippingRateId,
  )[0]

  // get currency symbol
  const currencySymbol = getSymbolFromCurrency(checkout?.currency || '')

  return (
    <div css={styles.container}>
      <div>
        <img css={styles.logo} src={logoUrl} alt="" />
        <Button
          dataTestId="cart-button"
          extendStyle={styles.button}
          onClick={() => setShowCart((prev) => !prev)}
        >
          <ShoppingCartIcon />
          <span>
            {checkout?.totalPrice
              ? `${currencySymbol}${parseFloat(checkout!.totalPrice).toFixed(2)}`
              : loading}
          </span>
          <ArrowLeftIcon rotateRight={showCart} />
        </Button>
      </div>

      <div css={styles.steps}>
        <Step
          linkTo={urlCreator('/delivery')}
          completed={!loading}
          accessible={
            !loading &&
            pathname !== '/delivery' &&
            pathname !== '/finish' &&
            pathname !== '/save-details'
          }
          id="delivery-step_title"
        >
          1. Delivery
        </Step>
        <Step
          linkTo={urlCreator('/shipping')}
          completed={!loading && Boolean(checkout?.selectedShippingRateId)}
          accessible={!loading && pathname === '/payment'}
          id="shipping-step_title"
        >
          2. Shipping Method
        </Step>
        <Step
          linkTo={urlCreator('/payment')}
          completed={pathname.includes('/finish')}
          accessible={false}
          id="payment-step_title"
        >
          3. Payment
        </Step>
      </div>

      <div css={styles.info}>
        {pathname !== '/delivery' &&
          pathname !== '/finish' &&
          pathname !== '/save-details' && (
            <Fragment>
              <Info
                title="Your info"
                values={[`${firstName} ${lastName}`, `${email}`]}
                onEdit={() => dispatch(push(urlCreator('/delivery')))}
                dataTestId="info_your-info"
                editId="edit_your-info"
              />
              <Info
                title="Shipping to"
                values={[
                  `${address1}`,
                  `${city} ${provinceCode || province}`,
                  `${zip}`,
                  `${country}`,
                ]}
                onEdit={() => dispatch(push(urlCreator('/delivery')))}
                dataTestId="info_shipping-to"
                editId="edit_shipping-to"
              />
              {pathname === '/payment' && (
                <Info
                  title={selectedShippingRate?.title || ''}
                  values={
                    checkoutSettings?.vendorLocalPickupRateTitle?.toLowerCase() ===
                    selectedShippingRate?.title?.toLowerCase()
                      ? [checkoutSettings?.localPickupRateDescription as string]
                      : [`${currencySymbol}${selectedShippingRate?.price}`]
                  }
                  onEdit={() => dispatch(push(urlCreator(`/shipping`)))}
                  dataTestId="info_shipping-rate"
                  bodyTestId="info_shipping-rate-body"
                  editId="edit_shipping-rate"
                />
              )}
            </Fragment>
          )}
      </div>
    </div>
  )
}
