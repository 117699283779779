import { FC } from 'react'
import { Product as IProduct } from '@softcery/qc-apiclient'
import { Price } from './components'
import { styles } from './styles'

interface ProductProps {
  product: IProduct
}

export const Product: FC<ProductProps> = ({ product }) => {
  const productPrice = (
    <Price price={product.price || ''} discountedPrice={product.discountedPrice} />
  )
  return (
    <div css={styles.container}>
      <img css={styles.image} src={product.imageURL} alt="product" />
      <div>
        <div css={styles.title}>{product.title}</div>
        <div css={styles.info}>
          {product.variantTitle !== '' && <span>{product.variantTitle}</span>}
          <span>QTY: {product.quantity}</span>
          {!product.variantTitle && productPrice}
        </div>
        {product.variantTitle && productPrice}
      </div>
    </div>
  )
}
