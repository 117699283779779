import {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { Form as FormikForm, FormikProps, FormikValues } from 'formik'
import { GetCheckCustomerRequest, GetCustomerRequest } from '@softcery/qc-apiclient'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useWindowSize } from 'react-use'
import debounce from 'lodash.debounce'
import mixpanel from 'mixpanel-browser'

import {
  createSmsCode,
  getCheckCustomer,
  getCustomer,
  setAcceptsSavingDetails,
  setCustomerCode,
  setDelivery,
  setCustomerExists,
  useAppDispatch,
  useAppSelector,
} from '~/redux'
import { Delivery, Option, PlaceDetails, PlaceOption } from '~/redux/types'
import {
  SubmitButton,
  Input,
  AddTitle,
  Error,
  CodeInput,
  CheckboxWithLabel,
} from '~/components'
import { BreakpointWidth } from '~/shared/styles'

import { styles } from './styles'
import { CANADA_PROVINCES, COUNTRIES, USA_STATES } from '../../constants'
import { AutocompleteInput, GoogleAutocompleteInput, PhoneInput } from '..'

interface FormProps extends Omit<FormikProps<FormikValues>, 'setFieldValue'> {
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
  setFieldValue: (field: keyof Delivery, value: string, shouldValidate?: boolean) => void
}

export const Form: FC<FormProps> = ({
  submitCount,

  setFieldValue,
  setIsSubmitted,
  ...props
}) => {
  const dispatch = useAppDispatch()
  // get checkout state
  const { meta, delivery, shipping, customer, saveDetails } = useAppSelector(
    (s) => s.checkout,
  )
  const { checkoutSettings } = useAppSelector((s) => s.checkout.checkout)

  const codeInputRef = useRef(null)

  const savingDetailsEnableStatus =
    useAppSelector(
      (s) => s.checkout.checkout.checkoutSettings?.savingDetailsEnableStatus,
    ) || ''

  // state for additional inputs
  const [showAddress2, setShowAddress2] = useState<boolean>(Boolean(delivery.address2))
  const [showCompanyName, setShowCompanyName] = useState<boolean>(
    Boolean(delivery.company),
  )

  // use for don't send verification code to the same number
  const [enteredPhoneNumbers] = useState(new Set())

  // get window width
  const { width } = useWindowSize()
  // checks customer existing and if exist send code to phone
  const debouncedCheckCustomer = useCallback(
    debounce((args: GetCheckCustomerRequest) => {
      if (
        !customer.code &&
        savingDetailsEnableStatus === 'enabled' &&
        !enteredPhoneNumbers.has(args.phone)
      ) {
        dispatch(getCheckCustomer(args)).then((action) => {
          if (action.meta.requestStatus === 'fulfilled') {
            enteredPhoneNumbers.add(args.phone)

            dispatch(
              createSmsCode({
                phone: args.phone || '',
                shop: meta.vendorId,
              }),
            )
            dispatch(
              setDelivery({
                phone: args.phone,
                country: props.values.country,
              }),
            )
          }
        })
      }
    }, 1000),
    [],
  )

  // sends code and if code is valid prefill delivery
  const sendCode = (args: GetCustomerRequest) => {
    if (!customer.code && savingDetailsEnableStatus === 'enabled') {
      dispatch(getCustomer(args)).then((action) => {
        if (action.meta.requestStatus === 'fulfilled') {
          // prefill delivery
          const delivery = action.payload.customer?.delivery
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(delivery)) {
            if (props.values[key] === '') {
              setFieldValue(key as keyof Delivery, (value as string) || '')
            }
          }

          dispatch(setCustomerCode(args.code || ''))

          // identify customer by his unique ID for mixpanel analytics
          mixpanel.identify(action.payload.customer?.id)

          mixpanel.people.set({
            customerName: `${delivery?.firstName} ${delivery?.lastName}`,
            customerEmail: delivery?.email,
            customerPhone: delivery?.phone,
          })

          mixpanel.track('Logged in')
        }
      })
    }
  }

  // validate fields onBlur and onChange only when submit count > 0
  useEffect(() => {
    setIsSubmitted(Boolean(submitCount))
  }, [submitCount])

  // initialize places service
  const places = usePlacesService({
    apiKey: 'AIzaSyCEd6o7lGtUEEFJ0r4XuGeeP9UJ0Afj_2Q',
    options: { types: ['address'] },
    debounce: 500,
    language: 'en',
  })

  // get full address when address line 1 filled
  const getFullAddress = (
    placeId: string,
    setFieldValue: (
      field: keyof Delivery,
      value: string,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    places.placesService?.getDetails(
      { placeId, fields: ['address_components'] },
      (placeDetails: PlaceDetails) => {
        // [street_number?, route]
        const addressLine: string[] = []
        // [postal_code, postal_code_suffix?]
        const zip: string[] = []
        let city = ''
        placeDetails?.address_components.forEach((addressComponent) => {
          const types: string[] = [...addressComponent.types]

          switch (true) {
            // street number
            case types.includes('street_number'):
              return addressLine.push(addressComponent.long_name)
            // street name
            case types.includes('route'):
              return addressLine.push(addressComponent.long_name)
            // city
            case types.includes('locality'):
              city = addressComponent.long_name
              return undefined
            case types.includes('sublocality_level_1'):
              city = addressComponent.long_name
              return undefined
            // country
            case types.includes('country'):
              return setFieldValue('country', addressComponent.long_name)
            // state
            case types.includes('administrative_area_level_1'):
              setFieldValue('province', addressComponent.long_name)
              setFieldValue('provinceCode', '')
              // in some cases google autocomplete doesn't return address component with type 'locality'
              if (!city) {
                city = addressComponent.long_name
              }
              return undefined
            // zipcode 5 digits
            case types.includes('postal_code'):
              return zip.push(addressComponent.long_name)
            // zipcode suffix
            case types.includes('postal_code_suffix'):
              return zip.push(addressComponent.long_name)
            default:
              return undefined
          }
        })

        // google place api first return street_number then route, so we need to reverse first then join
        setFieldValue('city', city)
        setFieldValue('address1', addressLine.join(', '))
        setFieldValue('zip', zip.join('-'))
      },
    )
  }

  const provinceInput = useMemo(() => {
    switch (props.values.country) {
      case 'United States':
        return {
          label: 'Select State',
          options: USA_STATES,
        }
      case 'Canada':
        return {
          label: 'Select Province',
          options: CANADA_PROVINCES,
        }
      default:
        return {
          label: 'District',
          options: [],
        }
    }
  }, [props.values.country])

  const isCodeInputVisible =
    customer?.customerExists && !customer.successfullyGetInformation && !customer.code

  const codeInputFocus = () => {
    // @ts-expect-error
    codeInputRef.current?.textInput[0].focus()
  }

  useEffect(() => {
    if (isCodeInputVisible) {
      setTimeout(codeInputFocus, 100)
    }
  }, [customer?.customerExists])

  return (
    <FormikForm>
      <div
        css={[
          styles.pullInformation.container.base,
          isCodeInputVisible && styles.pullInformation.container.visible,
        ]}
      >
        <span
          css={styles.pullInformation.closeButton}
          onClick={() => {
            enteredPhoneNumbers.clear()
            dispatch(setCustomerExists(false))
          }}
        >
          &#x2715;
        </span>

        <div css={styles.pullInformation.title}>Pull up your information</div>

        <div css={styles.pullInformation.infoTitle}>
          <span css={styles.pullInformation.enter}>Enter the code send to</span>{' '}
          <span css={styles.pullInformation.phone}>{props.values.phone}</span>
        </div>

        <CodeInput
          onCodeFill={(code) =>
            sendCode({
              code,
              phone: props.values.phone,
              shop: meta.vendorId,
            })
          }
          isValid={customer.error === ''}
          inputRef={codeInputRef}
        />

        {customer.error && (
          <div css={styles.pullInformation.errorMessage}>{customer.error}</div>
        )}
      </div>

      <div css={styles.container}>
        <Input name="email" label="Email address" />

        <PhoneInput
          onChange={(phone) => debouncedCheckCustomer({ phone, shop: meta.vendorId })}
          name="phone"
          label="Phone Number"
        />

        <Input name="firstName" label="First Name" />
        <Input name="lastName" label="Last Name" />

        <div css={styles.additionalField}>
          <AddTitle
            text="Add company name"
            onClick={() => setShowCompanyName((prev) => !prev)}
            dataTestId="addCompany_button"
          />
          {showCompanyName && <Input name="company" label="Company name" />}
        </div>

        <GoogleAutocompleteInput
          places={places}
          onOptionClick={(option: PlaceOption) => {
            getFullAddress(option.place_id, setFieldValue)
          }}
          name="address1"
          label="Address Line 1"
          dropdownDataTestId="address1_dropdown"
        />

        {/* tablets and desktop devices */}
        {width >= BreakpointWidth.MOBILE && (
          <AutocompleteInput
            name="country"
            label="Country"
            onOptionClick={(option: Option) => {
              if (option) {
                setFieldValue('country', option.name)
              }
            }}
            options={COUNTRIES}
            autocompleteDataTestId="country_dropdown"
          />
        )}

        <div css={styles.additionalField}>
          <AddTitle
            text="Add address line 2"
            onClick={() => setShowAddress2((prev) => !prev)}
            dataTestId="addAddress2_button"
          />

          {showAddress2 && <Input name="address2" label="Address Line 2" />}
        </div>

        <div css={styles.address}>
          {/* mobile devices */}
          {width <= BreakpointWidth.MOBILE && (
            <AutocompleteInput
              name="country"
              label="Country"
              onOptionClick={(option: Option) => {
                if (option) {
                  setFieldValue('country', option.name)
                }
              }}
              options={COUNTRIES}
            />
          )}

          <Input name="city" label="City" id="city_input" />

          <AutocompleteInput
            name="province"
            label={provinceInput.label}
            onOptionClick={(option: Option) => {
              if (option) {
                setFieldValue('province', option.name.split(',')[0])
                setFieldValue('provinceCode', option.code)
              }
            }}
            options={provinceInput.options}
            autocompleteDataTestId="province_dropdown"
          />

          <Input name="zip" label="Zip Code" />
        </div>

        {meta.error && <Error>{meta.error}</Error>}
      </div>

      {checkoutSettings?.savingDetailsEnableStatus === 'enabled' &&
        !customer.successfullyGetInformation &&
        !saveDetails.successfullySavedInformation && (
          <CheckboxWithLabel
            setChecked={() =>
              dispatch(setAcceptsSavingDetails(!saveDetails.acceptsSavingDetails))
            }
            checked={saveDetails.acceptsSavingDetails}
            extendStyle={styles.checkbox}
            label="Save my details for a faster checkout"
          />
        )}

      <SubmitButton
        text="Continue to shipping method"
        loading={meta.loading || shipping.loading}
        dataTestId="delivery_submitButton"
      />
    </FormikForm>
  )
}
