import { useTheme } from '@emotion/react'
import qs from 'querystring'
import { FC, useEffect, MouseEvent } from 'react'
import { Cart } from '~/components'
import { styles } from './styles'

interface PopupProps {
  showCart: boolean
  onClose: () => void
}

// Popup provides a universal popup.
export const Popup: FC<PopupProps> = ({ onClose, showCart, children }) => {
  const theme = useTheme()
  // type of platform
  const { platform } = qs.parse(window.location.search.slice(1))
  // prevent scrolling of body
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [])

  return (
    <div
      id="popup-container"
      css={styles.popup(theme, platform as string)}
      onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
        // to close popup, when click on free space
        if (
          (e.target as HTMLDivElement).id === 'popup-container' ||
          (e.target as HTMLDivElement).id === 'cart-information-wrapper'
        ) {
          onClose()
        }
      }}
    >
      <div css={styles.dialog}>
        <div css={styles.content}>
          <div css={styles.header}>
            <img src={require('./assets/close.svg')} alt="close" onClick={onClose} />
          </div>
          <div css={styles.body}>{children}</div>
        </div>

        <Cart visible={showCart} />
      </div>
    </div>
  )
}
