import { FC, ButtonHTMLAttributes } from 'react'
import { Interpolation, Theme, useTheme } from '@emotion/react'
import { Button, Loader } from '..'
import { styles } from './styles'

interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  extendStyle?: {
    container?: Interpolation<Theme>
    button?: Interpolation<Theme>
  }
  loading: boolean
  text: string
  showIcon?: boolean
  dataTestId?: string
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  extendStyle,
  loading,
  text,
  showIcon = true,
  ...props
}) => {
  const theme = useTheme()
  return (
    <div css={[styles.container, extendStyle?.container]}>
      <Button
        type="submit"
        {...props}
        extendStyle={[styles.base, extendStyle?.button]}
        loading={loading}
      >
        {loading && <Loader extendStyle={styles.loader} fill={theme.LIGHT_2} />}
        <span css={styles.text}>{text}</span>
        {showIcon && (
          <img
            css={loading && styles.contrastIcon}
            src={require('./assets/arrow.svg')}
            alt="arrow"
          />
        )}
      </Button>
    </div>
  )
}
