import { Interpolation, Theme } from '@emotion/react'
import { FC, ButtonHTMLAttributes } from 'react'

import { styles } from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  extendStyle?: Interpolation<Theme>
  dataTestId?: string
}

// Button provides a universal button.
// Used on Delivery, Shipping, Payment screens
export const Button: FC<ButtonProps> = ({
  loading,
  extendStyle,
  children,
  dataTestId,
  disabled,

  ...props
}) => {
  return (
    <button
      {...props}
      disabled={loading || disabled}
      css={[styles.button, loading && styles.loading, extendStyle]}
      tabIndex={0}
      data-testid={dataTestId}
    >
      {children}
    </button>
  )
}
