import { FC } from 'react'
import { styles } from './styles'

interface PriceProps {
  discountedPrice?: string
  price: string
}

export const Price: FC<PriceProps> = ({ discountedPrice, price }) => {
  return (
    <span css={styles.container}>
      PRICE:&nbsp;
      <span data-testid="productPrice" css={discountedPrice && styles.lineThrough}>
        {parseFloat(price || '').toFixed(2)}
      </span>
      {discountedPrice && (
        <span data-testid="productDiscountedPrice" css={styles.discountedPrice}>
          {parseFloat(discountedPrice || '').toFixed(2)}
        </span>
      )}
    </span>
  )
}
