import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { push } from 'connected-react-router'
import { Payment } from '@softcery/qc-apiclient'
import querystring from 'querystring'
import { useTheme } from '@emotion/react'
import mixpanel from 'mixpanel-browser'

import {
  getFacebookAnalytics,
  getGoogleAnalyticsV3,
  getGoogleAnalyticsV4,
} from '~/analytics'
import {
  useAppDispatch,
  useAppSelector,
  setPayment,
  completeCheckout as completeCheckoutThunk,
  setTokenex,
} from '~/redux'
import { urlCreator } from '~/shared/lib'

import { Form } from './components'
import {
  TokenexIframes,
  TokenexValidateEventListenerData,
  TokenexTokenizeEventListenerData,
} from './types'
import { validationSchema } from './validation'
import { getTokenexIframeStyles } from './styles'
import placeholderIcon from './assets/card-placeholder.svg'
import visaIcon from './assets/visa.svg'
import mastercardIcon from './assets/mastercard.svg'
import americanExpressIcon from './assets/amex.svg'
import discoverIcon from './assets/discover.svg'

declare global {
  interface Window {
    TokenEx: any
  }
}

export const PaymentScreen: FC = () => {
  const theme = useTheme()
  // get dispatch
  const dispatch = useAppDispatch()

  const { saveDetails } = useAppSelector((s) => s.checkout)
  // get vendorId
  const { vendorId } = useAppSelector((s) => s.checkout.meta)

  // checkout state
  const { tokenexId, tokenexAuthKey, tokenexTimestamp, checkout } = useAppSelector(
    (s) => s.checkout.checkout,
  )
  const customer = useAppSelector((s) => s.checkout.customer)

  // delivery state
  const { firstName, lastName } = useAppSelector((s) => s.checkout.delivery)

  // payment state
  const { payment } = useAppSelector((s) => s.checkout.payment || {})

  // state for card icon
  const [cardType, setCardType] = useState<string>(payment?.ccType || '')
  const cardIcon: string = useMemo(() => {
    switch (cardType) {
      case 'visa':
        return visaIcon
      case 'masterCard':
        return mastercardIcon
      case 'americanExpress':
        return americanExpressIcon
      case 'discover':
        return discoverIcon
      default:
        return placeholderIcon
    }
  }, [cardType])

  // formik state need for validation and tokenization
  const [formikData, setFormikData] = useState<Payment>(payment || {})
  const [savedPaymentDetails, setSavedPaymentDetails] = useState<boolean>(
    customer.tokenexToken !== '',
  )

  // state for tokenex iframes
  const [iframes, setIframes] = useState<TokenexIframes>({
    ccNumber: {
      focused: false,
      error: '',
    },
    ccCvv: {
      focused: false,
      error: '',
    },
    isValid: false,
  })

  // refs for validation and tokenization
  const tokenexIframeRef = useRef<any>(null)
  const isFormikValidRef = useRef<boolean>(false)
  const isTokenexValidRef = useRef<boolean>(false)

  // update ref when iframes isValid state changed
  useEffect(() => {
    isTokenexValidRef.current = iframes.isValid
  }, [iframes.isValid])

  // track event "Payment section opened"
  useEffect(() => {
    mixpanel.track('Payment section opened', {
      storeDomain: vendorId,
      productIDs: checkout?.products?.map((product) => product.productId),
    })
  }, [])

  // add tokenex iframe
  useEffect(() => {
    // create tokenex iframe configuration object
    const iframeConfig = {
      tokenExID: tokenexId,
      tokenScheme: 'PCI',
      authenticationKey: tokenexAuthKey,
      timestamp: tokenexTimestamp,
      origin: `${window.location.origin},${
        querystring.parse(window.location.search.slice(1)).origin
      }`,
      pci: true,
      cvv: true,
      cvvContainerID: 'tokenexIframeCCCvvContainer',
      placeholder: 'Card Number',
      cvvPlaceholder: 'CVV',
      font: 'Montserrat',
      enablePrettyFormat: true,
      styles: {
        ...getTokenexIframeStyles(theme),
      },
    }

    // initialize iframe
    tokenexIframeRef.current = window.TokenEx?.Iframe(
      'tokenexIframeCCNumberContainer',
      iframeConfig,
    )

    // add event listeners
    tokenexIframeRef?.current?.on('load', () => console.debug('TokenEx iFrame Loaded'))
    tokenexIframeRef?.current?.on('focus', () =>
      setIframes((prev) => ({ ...prev, ccNumber: { ...prev.ccNumber, focused: true } })),
    )
    tokenexIframeRef?.current?.on(
      'cardTypeChange',
      (data: { possibleCardType: string }) => setCardType(data.possibleCardType),
    )
    tokenexIframeRef?.current?.on('cvvFocus', () =>
      setIframes((prev) => ({ ...prev, ccCvv: { ...prev.ccCvv, focused: true } })),
    )
    tokenexIframeRef?.current?.on(
      'validate',
      ({
        cvvValidator,
        isCvvValid,
        isValid,
        validator,
      }: TokenexValidateEventListenerData) => {
        console.debug('TokenEx iFrame validation')

        setIframes((prev) => ({
          ccNumber: {
            ...prev.ccNumber,
            error: validator || '',
          },
          ccCvv: {
            ...prev.ccCvv,
            error: cvvValidator || '',
          },
          isValid: isValid && isCvvValid,
        }))
      },
    )

    // load iframe to DOM
    tokenexIframeRef?.current?.load()
  }, [])

  const completeCheckout = ({
    firstSix,
    lastFour,
    cardType,
    token,
    tokenHMAC,
  }: Partial<TokenexTokenizeEventListenerData>) => {
    const payment = {
      ...formikData,
      ccFirstSix: firstSix,
      ccLastFour: lastFour,
      ccType: cardType,
    }

    dispatch(
      completeCheckoutThunk({
        vendorId,
        fields: {
          checkout: {
            ...checkout,
            payment,
          },
          tokenexToken: token,
          tokenexTokenHMAC: tokenHMAC,
        },
      }),
    ).then((t) => {
      if (t.meta.requestStatus === 'fulfilled') {
        dispatch(
          setPayment({
            payment,
            loading: false,
          }),
        )
        dispatch(
          setTokenex({ tokenexToken: token || '', tokenexTokenHMAC: tokenHMAC || '' }),
        )
        dispatch(
          push(
            urlCreator(
              saveDetails.acceptsSavingDetails &&
                !customer.successfullyGetInformation &&
                !saveDetails.successfullySavedInformation
                ? '/save-details'
                : '/finish',
            ),
          ),
        )
        if (checkout) {
          window.parent?.postMessage(JSON.stringify({ type: 'purchase' }), '*')

          // track event "Purchase"
          getGoogleAnalyticsV3()?.purchase(checkout)
          getGoogleAnalyticsV4()?.purchase(checkout)
          getFacebookAnalytics()?.purchase(checkout)

          // track event "Payment section completed"
          mixpanel.track('Payment section completed')
        }
      }
    })

    if (checkout) {
      getGoogleAnalyticsV3()?.addPaymentInfo(checkout)
      getGoogleAnalyticsV4()?.addPaymentInfo(checkout)
      getFacebookAnalytics()?.addPaymentInfo(checkout)
    }
  }

  // add tokenize event listener
  tokenexIframeRef?.current?.on(
    'tokenize',
    (fields: TokenexTokenizeEventListenerData) => {
      console.debug('TokenEx iFrame tokenization')
      completeCheckout({ ...fields })
    },
  )

  // submit form
  const onSubmit = (data: Payment) => {
    // start loading, button disabled
    dispatch(setPayment({ loading: true }))

    // set formik data to state
    setFormikData(data)

    // check validation and if it succeed - tokenize
    const timeoutId = setTimeout(() => {
      // check validation, tokenize if not saved information
      if (isTokenexValidRef.current && isFormikValidRef.current && !savedPaymentDetails) {
        tokenexIframeRef?.current?.tokenize()
      } else if (savedPaymentDetails) {
        // complete checkout with saved information
        completeCheckout({
          firstSix: payment?.ccFirstSix,
          lastFour: payment?.ccLastFour,
          cardType: payment?.ccType,
          token: customer.tokenexToken,
          tokenHMAC: customer.tokenexTokenHMAC,
        })
      } else {
        dispatch(setPayment({ loading: false }))
      }

      // clear timeout id
      clearTimeout(timeoutId)
    }, 1000)
  }

  return (
    <Formik
      initialValues={{
        // use first name and last name from delivery if empty
        ccName: payment?.ccName || `${firstName} ${lastName}`,
        ccExpMonth: payment?.ccExpMonth,
        ccExpYear: payment?.ccExpYear,
        acceptsMarketingNewsletter: payment?.acceptsMarketingNewsletter,
        discountCode: payment?.discountCode,
      }}
      // if saved information do not validate
      validationSchema={savedPaymentDetails ? null : validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<FormikValues>) => (
        <Form
          {...props}
          isSavedPaymentDetails={savedPaymentDetails}
          setSavedPaymentDetails={setSavedPaymentDetails}
          cardIcon={cardIcon}
          cardType={cardType}
          resetCardType={() => setCardType('')}
          iframes={iframes}
          tokenexIframeRef={tokenexIframeRef}
          setIsFormikValidRef={(isValid) => {
            isFormikValidRef.current = isValid
          }}
        />
      )}
    </Formik>
  )
}
