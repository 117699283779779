import { FC } from 'react'
import { Field, FieldProps, useFormikContext, FormikValues } from 'formik'
import ReactPhoneInput2 from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './styles.css'
import { setDelivery, useAppDispatch } from '~/redux'

interface PhoneInputProps {
  name: string
  label: string
  onChange: (value: string) => void
}

export const PhoneInput: FC<PhoneInputProps> = ({ name, label, onChange, ...props }) => {
  const { values } = useFormikContext<FormikValues>()
  const dispatch = useAppDispatch()

  return (
    <Field {...props} name={name}>
      {({ field, meta, form: { setFieldValue } }: FieldProps<FormikValues>) => (
        <div className="phone-input-container">
          <ReactPhoneInput2
            {...field}
            country="us"
            inputProps={{
              name,
              autoComplete: 'tel',
              'data-testid': `${name}_input`,
            }}
            inputClass={meta.error && 'form-control-error'}
            specialLabel={label}
            value={field.value as unknown as string}
            onChange={(value, country: { name: string }, event, formattedValue) => {
              setFieldValue(name, formattedValue, false)

              // change country and province input
              if (country.name !== values.country) {
                // changing country input value
                setFieldValue('country', country.name, false)
                // clear province input value
                setFieldValue('province', '', false)
                // dispatch to redux for sync with local storage
                dispatch(setDelivery({ country: country.name, province: '' }))
              }
              onChange(formattedValue)
            }}
          />
          <div className="error-message" id={`${name}_input-error`}>
            {meta.error}
          </div>
        </div>
      )}
    </Field>
  )
}
