import { FC, useState, useEffect } from 'react'
import { useFormikContext, FormikValues } from 'formik'
import { Input } from '~/components'
import { Option } from '~/redux/types'
import { styles } from './styles'

interface AutocompleteInputProps {
  label: string
  name: string
  options: Option[]
  autocompleteDataTestId?: string

  onOptionClick: (option: Option) => void
}

export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  name,
  options,
  autocompleteDataTestId,

  onOptionClick,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [predictions, setPredictions] = useState<Option[]>([])

  const { values } = useFormikContext<FormikValues>()

  // filtering options when value changed
  useEffect(() => {
    setPredictions(filterOptions(options, values[name]))
  }, [values[name], options])

  useEffect(() => {
    // do not show prediction if only one in list
    if (predictions[0]?.name === values[name]) {
      setShowOptions(false)
      onOptionClick(predictions[0])
    }
  }, [predictions])

  // func for filtering options
  const filterOptions = (options: Option[], value: string) =>
    options.filter(
      (option) =>
        // starts with first typed char
        (option.name.toLowerCase().startsWith(value.charAt(0).toLowerCase()) &&
          // include typed chars
          option.name.toLowerCase().includes(value.toLowerCase())) ||
        option.code.toLowerCase() === value.toLowerCase(),
    )

  return (
    <div
      css={styles.container}
      onFocus={() => {
        // don't show predictions if only one in list
        if (predictions.length !== 1) {
          setShowOptions(true)
        }
      }}
      onBlur={() => {
        // select first prediction
        onOptionClick(predictions[0])
        setShowOptions(false)
      }}
    >
      <Input
        {...props}
        autoComplete="off"
        onChangeCustom={(e) => {
          if (!showOptions) {
            setShowOptions(true)
          }
          return e
        }}
        name={name}
      />

      {predictions.length > 0 && showOptions ? (
        <div css={styles.dropdown.container} data-testid={autocompleteDataTestId}>
          {predictions.map((option) => {
            return (
              <div
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  onOptionClick(option)
                  setShowOptions(false)
                }}
                key={option.name}
                css={styles.dropdown.item}
              >
                {option.name.split(',')[0]}
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
