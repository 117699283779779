import { FC, Fragment, useState, useEffect } from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { push } from 'connected-react-router'
import mixpanel from 'mixpanel-browser'

import { useAppDispatch, useAppSelector, updateShippingRate } from '~/redux'
import { urlCreator } from '~/shared/lib'

import { styles } from './styles'
import { validationSchema } from './validation'
import { Form } from './components'

export const ShippingScreen: FC = () => {
  // get dispatch
  const dispatch = useAppDispatch()

  // get selected checkout and shipping rate id
  const { checkout } = useAppSelector((s) => s.checkout.checkout)
  const shipping = useAppSelector((s) => s.checkout.shipping)

  const selectedShippingRateId = (
    shipping.selectedShippingRateId !== ''
      ? shipping.selectedShippingRateId
      : shipping.shippingRates[0].vendorId
  ) as string

  const [isFieldError, setIsFieldError] = useState<boolean>(false)

  // track event "Shipping section opened"
  useEffect(() => {
    mixpanel.track('Shipping section opened')
  }, [])

  // submit form
  const onSubmit = ({
    selectedShippingRateId,
  }: {
    selectedShippingRateId: string
  }): void => {
    // update shipping rate
    dispatch(
      updateShippingRate({
        checkout: {
          ...checkout,
          selectedShippingRateId,
        },
      }),
    ).then((t) => {
      if (t.meta.requestStatus === 'fulfilled') {
        // track event "Shipping section completed"
        mixpanel.track('Shipping section completed')

        // navigate to Payment screen
        dispatch(push(urlCreator('/payment')))
      }
    })
  }

  return (
    <Fragment>
      <h2 css={[styles.title.base, isFieldError && styles.title.error]}>
        Choose your shipping method:
      </h2>
      <Formik
        initialValues={{ selectedShippingRateId }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props: FormikProps<FormikValues>) => (
          <Form
            {...props}
            isFieldError={isFieldError}
            setIsFieldError={setIsFieldError}
          />
        )}
      </Formik>
    </Fragment>
  )
}
