import { CheckoutStyles } from '@softcery/qc-apiclient'
import { Theme } from '@emotion/react'
import hexToRgba from 'hex-to-rgba'
import color from 'color'

// Used to create emotion theme with values from qc-api
export const makeTheme = (styles: CheckoutStyles) => {
  let {
    accentColor,
    checkoutBackgroundColor,
    summaryBackgroundColor,
    summaryTextColor,
    summaryButtonBackgroundColor,
    summaryButtonTextColor,
    textColor,
    buttonsColor,
  } = styles

  accentColor = accentColor || ''
  checkoutBackgroundColor = checkoutBackgroundColor || ''
  summaryBackgroundColor = summaryBackgroundColor || ''
  summaryTextColor = summaryTextColor || ''
  summaryButtonBackgroundColor = summaryButtonBackgroundColor || ''
  summaryButtonTextColor = summaryButtonTextColor || ''
  textColor = textColor || ''
  buttonsColor = buttonsColor || ''

  // theme colors
  const Colors: Theme = {
    DARK_0: hexToRgba(textColor, '1'),
    DARK_1: hexToRgba(textColor, '0.75'),
    DARK_2: hexToRgba(textColor, '0.5'),
    DARK_5: hexToRgba(textColor, '0.35'),
    DARK_3: hexToRgba(textColor, '0.3'),
    DARK_9: hexToRgba(textColor, '0.14'),
    DARK_4: color(textColor).grayscale().lightness(56).hex(),
    //  #8f8f8f by default
    DARK_10: color(textColor).grayscale().lightness(45).hex(),
    // #737373 by default
    DARK_BLUE_1: 'rgba(0, 114, 188, 1)',

    LIGHT_1: '#ffffff',
    LIGHT_2: hexToRgba(summaryButtonBackgroundColor, '0.75'),

    ACCENT_COLOR: accentColor,
    SUMMARY_BACKGROUND_COLOR: summaryBackgroundColor,
    // always transparency 1
    CHECKOUT_BACKGROUND_COLOR: hexToRgba(checkoutBackgroundColor, '1'),
    BUTTONS_BACKGROUND_COLOR: buttonsColor,
    SUMMARY_TEXT_COLOR: summaryTextColor,
    SUMMARY_SECONDARY_TEXT_COLOR: color(summaryBackgroundColor)
      .grayscale()
      .lightness(60)
      .hex(),
    // #999999 by default
    SUMMARY_BUTTON_TEXT_COLOR: summaryButtonTextColor,
    SUMMARY_BUTTON_BACKGROUND_COLOR: summaryButtonBackgroundColor,
    DROPDOWN_HIGHLIGHT_TEXT_COLOR: hexToRgba(summaryButtonTextColor, '0.75'),
    DROPDOWN_HIGHLIGHT_SECONDARY_TEXT_COLOR: color(summaryButtonTextColor)
      .lightness(65)
      .hex(),

    ERROR_1: 'rgba(240, 93, 46, 1)',
    SUCCESS_0: 'rgba(0, 195, 55, 1)',
  }

  // set css variables
  const rootStyle = document.documentElement.style
  rootStyle.setProperty('--DARK_1', Colors.DARK_1)
  rootStyle.setProperty('--DARK_4', Colors.DARK_4)
  rootStyle.setProperty('--DARK_9', Colors.DARK_9)
  rootStyle.setProperty('--CHECKOUT_BACKGROUND_COLOR', Colors.CHECKOUT_BACKGROUND_COLOR)
  rootStyle.setProperty(
    '--SUMMARY_BUTTON_BACKGROUND_COLOR',
    Colors.SUMMARY_BUTTON_BACKGROUND_COLOR,
  )
  rootStyle.setProperty(
    '--DROPDOWN_HIGHLIGHT_TEXT_COLOR',
    Colors.DROPDOWN_HIGHLIGHT_TEXT_COLOR,
  )
  rootStyle.setProperty(
    '--DROPDOWN_HIGHLIGHT_SECONDARY_TEXT_COLOR',
    Colors.DROPDOWN_HIGHLIGHT_SECONDARY_TEXT_COLOR,
  )
  rootStyle.setProperty('--LIGHT_4', Colors.SUMMARY_BUTTON_BACKGROUND_COLOR)
  rootStyle.setProperty('--ERROR_1', 'rgba(240, 93, 46, 1)')

  return Colors
}
